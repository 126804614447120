import * as React from "react";
import PropTypes from "prop-types";

import { Link, graphql } from "gatsby";

import Layout from "@layout";
import SEO from "@components/seo";
import Header from "@layout/header/layout-01";
import Footer from "@layout/footer/layout-01";
import Comment from "@components/comment";
import Image from "@ui/image";
import { Clock, ArrowUpRight } from "react-feather";

import { ImageType, SectionTitleType, BlogType } from "@utils/types";

const BlogPostTemplate = ({ data, location }) => {
    const post = data.article;
    const siteTitle = data.site.siteMetadata?.title || `Title`;
    const { previous, next } = data;
    const rootPath = `${__PATH_PREFIX__}/`;
    const isRootPath = location.pathname === rootPath;

    console.log(JSON.stringify(data.header, null, 4));

    return (
        <Layout location={location} title={siteTitle}>
            <Header
                data={{
                    ...data.header,
                    ...data.navigation,
                    socials: data.site.siteMetadata.socials,
                }}
                isHome={false}
            />
            <main className="main-page-wrapper">
                <div className="global-wrapper" data-is-root-path={isRootPath}>
                    {/* <article
                className="blog-post"
                itemScope
                itemType="http://schema.org/Article"
            >
                <header>
                    <h1 itemProp="headline">{post.title}</h1>
                    <p>{post.date}</p>
                </header>
                <section
                    dangerouslySetInnerHTML={{ __html: post.content }}
                    itemProp="articleBody"
                />
                <hr />
            </article> */}

                    {post.image?.src && (
                        <Image
                            src={post.image.src}
                            alt={post.image?.alt || post.title}
                            className="img-fluid modal-feat-img"
                        />
                    )}

                    <div className="news-details">

                        <div className="category-info">
                            <div className="category-list">
                                <span>{post.category}</span>
                            </div>
                            <div className="meta">
                                {post.readingTime && (
                                    <span>
                                        <Clock size={13} /> {post.readingTime}
                                    </span>
                                )}
                            </div>
                        </div>
                        <span className="date">{post.date}</span>
                        <h1 className="title">{post.title}</h1>
                        <div
                            dangerouslySetInnerHTML={{ __html: post.content }}
                        />
                    </div>
                    <Comment slug={post.slug} id={post.id} title={post.title} />
                    <nav className="blog-post-nav">
                        <ul
                            style={{
                                display: `flex`,
                                flexWrap: `wrap`,
                                justifyContent: `space-between`,
                                listStyle: `none`,
                                padding: 0,
                            }}
                        >
                            <li>
                                {previous && (
                                    <Link to={`/${previous.slug}`} rel="prev">
                                        ← {previous.title}
                                    </Link>
                                )}
                            </li>
                            <li>
                                {next && (
                                    <Link to={`/${next.slug}`} rel="next">
                                        {next.title} →
                                    </Link>
                                )}
                            </li>
                        </ul>
                    </nav>
                </div>
            </main>
            <Footer
                data={{
                    ...data.footer,
                    getnewsletter_url:
                        data.site.siteMetadata?.getnewsletter_url,
                }}
                className="section-separator"
            />
        </Layout>
    );
};

BlogPostTemplate.propTypes = {
    data: PropTypes.shape({
        site: PropTypes.shape({
            siteMetadata: PropTypes.shape({
                socials: PropTypes.arrayOf(PropTypes.shape({})),
                contact: PropTypes.shape({
                    phone: PropTypes.string,
                    email: PropTypes.string,
                }),
                getform_url: PropTypes.string,
                getnewsletter_url: PropTypes.string,
            }),
        }),
        article: PropTypes.shape(BlogType),
        // PropTypes.shape({
        //     id: PropTypes.string,
        //     title: PropTypes.string,
        //     content: PropTypes.string,
        //     date: PropTypes.shape({}),
        //     category: PropTypes.string,
        //     excerpt: PropTypes.string,
        //     image: PropTypes.shape(ImageType),
        // }),
        previous: PropTypes.shape({
            slug: PropTypes.string,
            title: PropTypes.string,
        }),
        next: PropTypes.shape({
            slug: PropTypes.string,
            title: PropTypes.string,
        }),
        navigation: PropTypes.shape({}),
        header: PropTypes.shape({}),
        footer: PropTypes.shape({}),
    }),
    location: PropTypes.string,
};

export default BlogPostTemplate;

export const pageQuery = graphql`
    query BlogPostBySlug(
        $id: String!
        $previousPostId: String
        $nextPostId: String
    ) {
        site {
            ...Site
        }
        article(parent: { id: { eq: $id } }) {
            ...Article
        }
        previous: article(parent: { id: { eq: $previousPostId } }) {
            slug
            title
        }
        next: article(parent: { id: { eq: $nextPostId } }) {
            slug
            title
        }
        header: general(section: { eq: "header-1" }) {
            ...Header01
        }
        navigation: general(section: { eq: "menu-1" }) {
            menu {
                ...Menu01
            }
        }
        footer: general(section: { eq: "footer-1" }) {
            ...Footer01
        }
    }
`;
